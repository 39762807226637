import { styled, useTheme } from "@mui/material";
import React from "react";
import Link from "next/link";
import { shallow } from "zustand/shallow";
import { useConfigStore } from "@/stores/configStore";

type Props = {
  src: string;
  height?: number;
  alt?: string;
  isLink?: boolean
};

const Logo = ({ src = "", alt = "Logo", height = 70, isLink = true }: Props) => {
  const [config] = useConfigStore((state) => [state.config], shallow);

  return isLink ? (
    <Link href={"/"}>
      <StyledLogo
        sx={{
          height: {
            xs: height - (height * 0.4) + 'px',
            sm: height - (height * 0.2) + 'px',
            md: height + 'px',
            lg: height + 'px',
            xl: height + 'px',
          },
        }}
        src={src}
        alt={"LOGO"}
      />
    </Link>
  ) : (
    <StyledLogo
      sx={{
        height: {
          xs: height - (height * 0.4) + 'px',
          sm: height - (height * 0.2) + 'px',
          md: height + 'px',
          lg: height + 'px',
          xl: height + 'px',
        },
        ...(config?.agent_name !== 'temmed' && {
          maxWidth: '100%',
          height: 'auto'
        })
      }}
      src={src}
      alt={"LOGO"}
    />
  );
};

const StyledLogo = styled("img")(({ theme }) => ({
  width: "auto",
  cursor: "pointer",
}));

export default Logo;
