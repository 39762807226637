import * as React from "react";
import Box from "@mui/material/Box";
import {
  Button,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  Stack,
  TextField,
  Typography,
} from "@mui/material";

import { LoadingButton } from "@mui/lab";

import * as yup from "yup";
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useSignUpStore } from "@/stores/signUpStore";
import { shallow } from "zustand/shallow";
import { RegisterPayload, User } from "@/@types/user";
import { Check, Send } from "@mui/icons-material";
import { SnackbarProvider, enqueueSnackbar, closeSnackbar } from "notistack";
import { useAuthStore } from "@/stores/authStore";
import { useRouter } from "next/navigation";
import Turnstile, { useTurnstile } from "react-turnstile";
import { useAppModalStore } from "@/stores/appModalStore";
import { useConfig, useUserProfile } from "@/hooks";
import { useConfigStore } from "@/stores/configStore";

type Inputs = {
  full_name?: string;
  username: string;
  password: string;
  confirmPassword: string;
};

const schema = yup.object().shape({
  full_name: yup
    .string()
    .matches(/^([^0-9]*)$/, "ชื่อ-นามสกุลไม่ถูกต้อง")
    .optional(),
  username: yup
    .string()
    .matches(/^[0-9]+$/, "เบอร์โทรศัพท์ต้องเป็นตัวเลขเท่านั้น")
    .min(10, "เบอร์โทรศัพท์ต้องมีอย่างน้อย 10 ตัวอักษร")
    .max(10, "เบอร์โทรศัพท์ต้องมีไม่เกิน 10 ตัวอักษร")
    .required("กรุณากรอกเบอร์โทรศัพท์"),
  password: yup
    .string()
    .min(6, "รหัสผ่านต้องมีอย่างน้อย 6 ตัวอักษร")
    .max(30, "รหัสผ่านต้องมีไม่เกิน 30 ตัวอักษร")
    .required("กรุณากรอกรหัสผ่าน"),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref("password")], "รหัสผ่านไม่ตรงกัน")
    .required("กรุณากรอกยืนยันรหัสผ่าน"),
});

export default function FormVerifyBank() {
  const [config] = useConfigStore((state) => [state.config], shallow);
  // const turnstile = useTurnstile();
  // const { turnstileKey } = useConfig();
  const route = useRouter();

  const [captchaToken, setCaptchaToken] = React.useState("");

  const [setOpenAppModal, setData] = useAppModalStore(
    (state) => [state.setOpen, state.setData],
    shallow
  );

  const [loading, input, updateInput, register] = useSignUpStore(
    (state) => [state.loading, state.input, state.updateInput, state.register],
    shallow
  );

  const [login] = useAuthStore((state) => [state.login], shallow);

  const {
    control,
    handleSubmit,
    formState: { errors },
    setError
  } = useForm<Inputs>({ resolver: yupResolver(schema) });

  const handleRegister = async ({
    captchaToken,
    phoneNumber,
    password,
    full_name
  }: {
    captchaToken: string;
    phoneNumber: string;
    password: string;
    full_name: string | undefined;
  }) => {
    if (input) {
      if (!config?.is_verify_bank && (!full_name || full_name.trim()?.length === 0)) {
        enqueueSnackbar('กรุณากรอกชื่อ-นามสกุล', {
          variant: "error",
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
        });
        return
      }

      const payload: RegisterPayload = {
        captchaToken,
        phoneNumber,
        username: phoneNumber,
        password,
        bankCode: input.bankCode!,
        bankAccountNumber: input.bankAccountNumber!,
        fullName: !config?.is_verify_bank ? full_name as string : input.fullName!
      };
      const response = await register(payload);
      if (typeof response === "string") {
        enqueueSnackbar(response, {
          variant: "error",
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
        });
        // turnstile.reset();
        return;
      }

      setOpenAppModal(false);
      route.push("/");
    }
  };

  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    const { username: phoneNumber, password, full_name } = data;
    await handleRegister({ captchaToken, phoneNumber, password, full_name });
  };

  return (
    <Box p={2} component={"form"} onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={12}>
          <FormControl fullWidth>
            <Controller
              name="full_name"
              control={control}
              rules={{ required: !config?.is_verify_bank ? true : false }}
              render={({ field: { value, onChange, onBlur } }) => (
                <TextField
                  aria-readonly
                  id="full_name"
                  label="ชื่อ-นามสกุล"
                  // autoFocus
                  value={!config?.is_verify_bank ? value : input?.fullName!}
                  onBlur={!config?.is_verify_bank ? onBlur : () => { }}
                  onChange={!config?.is_verify_bank ? onChange : () => { }}
                  placeholder="ชื่อ-นามสกุล"
                />
              )}
            />
            {errors.full_name && (
              <FormHelperText sx={{ color: "error.main" }}>
                {errors.full_name.message}
              </FormHelperText>
            )}
          </FormControl>
        </Grid>
        <Grid item xs={12} md={12}>
          <FormControl fullWidth>
            <Controller
              name="username"
              control={control}
              rules={{ required: true }}
              render={({ field: { value, onChange, onBlur } }) => (
                <TextField
                  id="username"
                  // autoFocus
                  label="เบอร์โทรศัพท์"
                  sx={{
                    ...(errors.password && {
                      borderColor: (theme) => theme.gradient[900],
                      borderStyle: "solid",
                      borderWidth: 1,
                      borderRadius: "16px",
                      "& .MuiInputLabel-root": {
                        color: (theme) => theme.gradient[900],
                      }
                    })
                  }}
                  value={value}
                  onBlur={onBlur}
                  onChange={(e) => {
                    const numericValue = e.target.value.replace(/\D/g, "");
                    onChange(numericValue);
                  }}
                  error={Boolean(errors.username)}
                  placeholder="เบอร์โทรศัพท์"
                />
              )}
            />
            {errors.username && (
              <FormHelperText
                sx={{
                  ...(errors.password && {
                    borderColor: (theme) => theme.gradient[900] || "error.main",
                  })
                }}>
                {errors.username.message}
              </FormHelperText>
            )}
          </FormControl>
        </Grid>
        <Grid item xs={12} md={12}>
          <FormControl fullWidth>
            <Controller
              name="password"
              control={control}
              rules={{ required: true }}
              render={({ field: { value, onChange, onBlur } }) => (
                <TextField
                  id="password"
                  label="รหัสผ่าน"
                  sx={{
                    ...(errors.password && {
                      borderColor: (theme) => theme.gradient[900] || "error.main",
                      borderStyle: "solid",
                      borderWidth: 1,
                      borderRadius: "16px",
                      "& .MuiInputLabel-root": {
                        color: (theme) => theme.gradient[900] || "error.main",
                      }
                    })
                  }}
                  type="password"
                  value={value}
                  onBlur={onBlur}
                  onChange={onChange}
                  error={Boolean(errors.password)}
                  placeholder="รหัสผ่าน"
                />
              )}
            />
            {errors.password && (
              <FormHelperText
                sx={{
                  ...(errors.password && {
                    borderColor: (theme) => theme.gradient[900] || "error.main",
                  })
                }}>
                {errors.password.message}
              </FormHelperText>
            )}
          </FormControl>
        </Grid>
        <Grid item xs={12} md={12}>
          <FormControl fullWidth>
            <Controller
              name="confirmPassword"
              control={control}
              rules={{ required: true }}
              render={({ field: { value, onChange, onBlur } }) => (
                <TextField
                  id="confirmPassword"
                  label="ยืนยันรหัสผ่าน"
                  sx={{
                    ...(errors.password && {
                      borderColor: (theme) => theme.gradient[900] || "error.main",
                      borderStyle: "solid",
                      borderWidth: 1,
                      borderRadius: "16px",
                      "& .MuiInputLabel-root": {
                        color: (theme) => theme.gradient[900] || "error.main",
                      }
                    })
                  }}
                  type="password"
                  value={value}
                  onBlur={onBlur}
                  onChange={onChange}
                  error={Boolean(errors.confirmPassword)}
                  placeholder="ยืนยันรหัสผ่าน"
                />
              )}
            />
            {errors.confirmPassword && (
              <FormHelperText
                sx={{
                  ...(errors.password && {
                    borderColor: (theme) => theme.gradient[900] || "error.main",
                  })
                }}>
                {errors.confirmPassword.message}
              </FormHelperText>
            )}
          </FormControl>
        </Grid>
        {/* <Grid item xs={12} md={12}>
          <Turnstile
            sitekey={turnstileKey || ""}
            onVerify={(token) => setCaptchaToken(token)}
          />
        </Grid> */}
        <Grid item xs={12} md={12}>
          <LoadingButton
            loading={loading}
            startIcon={<Check />}
            type="submit"
            variant="contained"
            fullWidth
            style={{ height: "50px" }}
            sx={{
              background: 'linear-gradient(160deg, #b47e11, #fec33f, #e09c14);',
              color: '#000',
              height: '40px',
              outline: 'none;',
              textAlign: 'center;',
              transition: 'all .5s;',
              borderRadius: '50px;'
            }}
          >
            ลงทะเบียนและเข้าสู่ระบบ
          </LoadingButton>
        </Grid>
        <Grid item xs={12} md={12}>
          <Box mb={0}>
            <Divider>
              <Typography variant="subtitle1" align="center">
                หรือ
              </Typography>
            </Divider>
          </Box>
        </Grid>
        <Grid item xs={12} md={12}>
          <Stack direction="row" justifyContent="start">
            <Typography variant="subtitle1" align="center" sx={{ mr: 2 }}>
              มีบัญชีอยู่แล้ว
            </Typography>
            <FormControlLabel
              onClick={e => {
                e.preventDefault()
                route.push("/login");
              }}
              control={<Typography></Typography>}
              label="เข้าสู่ระบบ" sx={{ color: (theme) => '#fec33f' }}
            />
          </Stack>
        </Grid>
      </Grid>
    </Box>
  );
}
