"use client";

import * as React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import { Button, Grid, IconButton, Paper, StepContent, TextField, Typography, useMediaQuery, useTheme } from "@mui/material";
import FormVerifyOtp from "./FormVerifyOtp";
import { shallow } from "zustand/shallow";
import FormVerifyBank from "./FormVerifyBank";
import FormVerifyIdentify from "./FormVerifyIdentify";
import { useSignUpStore } from "@/stores/signUpStore";
import { useSearchParams } from "next/navigation";
import { Icon } from "@iconify/react";
import { Logo } from "@/components/logo";
import { useConfigStore } from "@/stores/configStore";

const stepContents = [FormVerifyBank, FormVerifyIdentify];

export default function StepperContent() {
  const theme = useTheme();
  const searchParams = useSearchParams();
  const ref = searchParams.get("ref");
  const [step] = useSignUpStore((state) => [state.step], shallow);
  const Content = stepContents[step];
  const isMD = useMediaQuery(theme.breakpoints.down("md"));
  const [config] = useConfigStore((state) => [state.config], shallow);

  // React.useEffect(() => {
  //   if (ref) {
  //     localStorage.setItem("ref", ref);
  //   }
  // }, [ref]);

  return (
    <Paper
      elevation={1}
      sx={{
        opacity: 'unset;',
        pointerEvents: 'unset;',
        marginLeft: 'auto !important;',
        marginRight: 'auto !important;',
        flex: '0 0 auto;',
        ...(!isMD && {
          width: '40%;'
        }),
        ...(theme.name === 'preset29' && {
          background: 'linear-gradient(rgb(215, 4, 4), rgb(24, 23, 55))'
        })
      }}
    >
      <Box sx={{
        position: config?.agent_name !== 'temmed' ? 'fixed' : 'absolute',
        top: 0,
        right: 0,
        padding: '.3rem 1.5rem .5rem'
      }}>
        <IconButton
          style={{
            padding: '0 3px'
          }}
          aria-label="clear"
          aria-controls="menu-appbar"
          aria-haspopup="true"
          onClick={() => {
          }}
          color="inherit"
        >
          <Icon
            id="icon"
            icon={"emojione:flag-for-thailand"}
            width={18}
            height={18}
            style={{ marginTop: '-2px' }}
          />
        </IconButton>
        <Typography
          variant="caption"
          mb={-1.2}
          color="white"
          sx={{ fontSize: '0.875rem', marginLeft: '3px' }}
        >
          {`Thai`}
        </Typography>
      </Box>
      <Box p={2}>
        <Box
          mb={3}
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <Logo
            src={
              config?.agent_name === 'temmed'
                ? `https://images-platform.sgp1.cdn.digitaloceanspaces.com/logo/LOGO%20TEM-MED.jpg`
                : config?.logo || ""
            }
            height={
              config?.agent_name === 'temmed'
                ? 400
                : 256
            }
            isLink={false}
          />
        </Box>
        <Box mb={0}>
          <Typography variant="h4" align="center">
            สมัครสมาชิก
          </Typography>
        </Box>
        {/* <SignUpStepper /> */}
        <Box mt={2}>
          <Content />
        </Box>
      </Box>
    </Paper>
  )
}
